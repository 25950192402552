import React from "react";

//Style
import "./BodyThanksPage.css";
import { Link } from "react-router-dom";

const BodyThanksPage = () => {
  return (
    <section className="body-thanks-container">
      <div className="deco-img-container">
        <img
          className="deco-img-thanks"
          src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/bgs/couch-with-plant-deco.webp"
          rel="preconnect"
          alt=""
        />
      </div>
      <div className="info-thanks-container">
        <div className="info-thanks-subcontainer">
          <img
            src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/assets/title-thanks.webp"
            rel="preconnect"
            alt=""
            className="thanks-sign-img"
          />
          <div className="info-btns-container">
            <div className="thnks-btn-container">
              <img
                src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/assets/call-btn-sab.svg"
                rel="preconnect"
                className="btn-thanks"
                alt=""
              />
              <div className="thnk-subtitle-container">
                <h2 className="subtitle">Llama</h2>
                <p>+57 (313) 831-6020</p>
              </div>
            </div>
            <a
              href="https://www.waze.com/es-419/live-map/directions?to=ll.5.026095%2C-73.993199"
              title="Waze"
              target="_blank"
              className="thnks-btn-container"
              rel="noreferrer"
            >
              <img
                src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/assets/waze-btn-sab.svg"
                rel="preconnect"
                className="btn-thanks"
                alt=""
              />
              <div className="thnk-subtitle-container">
                <h2 className="subtitle">Proyecto</h2>
                <p>Llega con Waze</p>
              </div>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=573138316020&text=%C2%A1Hola%2C%20me%20gustar%C3%ADa%20recibir%20mas%20informaci%C3%B3n!"
              title="Whatsapp"
              target="_blank"
              className="thnks-btn-container"
              rel="noreferrer"
            >
              <img
                src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/assets/wa-btn-sab.svg"
                rel="preconnect"
                className="btn-thanks"
                alt=""
              />
              <div className="thnk-subtitle-container">
                <h2 className="subtitle">Contacta</h2>
                <p>Por Whatsapp</p>
              </div>
            </a>
          </div>
          <div className="vt-tour-advise-container">
            <Link to="https://my.matterport.com/show/?m=Tctvxu8x1aF">
              <img
                className="vt-tour-advise-img"
                src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/assets/360-btn-deco.webp"
                rel="preconnect"
                alt=""
              />
            </Link>
          </div>
          <div className="common-areas-btns-container">
            <Link to="/">
              <img
                className="btn-common-areas"
                src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/assets/info-btn-1.webp"
                rel="preconnect"
                alt=""
              />
            </Link>
            <Link to="/">
              <img
                className="btn-common-areas"
                src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/thanks-page/assets/info-btn-2.webp"
                rel="preconnect"
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BodyThanksPage;
