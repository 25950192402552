import React from "react";
import data from "../../json/socialMedia.json";

//Styles
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-container">
      <div className="footer-subcontainer">
        <div className="f-preview-btn-container">
          <a
            className="preview-btn"
            href="https://6541151f0d3bf40a44cbca59--splendorous-genie-018573.netlify.app/"
            target="_blank"
            title="Avances de la obra"
            rel="noreferrer"
          >
            Conoce los avances de obra
          </a>
        </div>
        <div className="otacc-info-footer">
          <div className="info-otacc">
            <div className="crediseguro-info-container info-direction">
              <h1 className="subtitle-style">
                Construye <strong>OTACC S.A.</strong>
              </h1>
              <a className="anchor-style" href="https://www.otacc.com/">
                Inicio
              </a>
            </div>
            <div className="client-service-container info-direction">
              <h1 className="subtitle-style">
                Servicio al <strong>cliente</strong>
              </h1>
              <div className="email-data-container">
                <p className="anchor-style">Correo electronico:</p>
                <p className="anchor-style">comercial@otacc.com</p>
              </div>
              <div className="email-data-container">
                <p className="anchor-style">Teléfono:</p>
                <p className="anchor-style">
                  <a
                    className="anchor-style"
                    href="https://api.whatsapp.com/message/CNBXXM35FIWBN1?autoload=1&app_absent=0"
                    title="Whatsapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (320) 520-3601
                  </a>
                </p>
                <p className="anchor-style slash">/</p>
                <p className="anchor-style">
                  <a
                    className="anchor-style"
                    href="https://api.whatsapp.com/message/JASO4CUJ37KUH1?autoload=1&app_absent=0"
                    title="Whatsapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (300) 885-0983
                  </a>
                </p>
              </div>
              <p className="anchor-style">Dirección Sala de Ventas:</p>
              <p className="anchor-style">Transversal 21 #8-40 Zipaquirá</p>
            </div>
            <div className="info-container info-direction">
              <h1 className="subtitle-style">
                Información <strong>útil</strong>
              </h1>
              <a
                className="anchor-style"
                href="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/docs/FI-067+POLITICA+DE+TRATAMIENTO+DE+LA+INFORMACI%C3%93N+OTACC+S.A..pdf"
                target="_blank"
                rel="noreferrer"
              >
                Politica de privacidad
              </a>
            </div>
            <div className="info-container info-direction">
              <h1 className="subtitle-style sm-subtitle sms-screen">
                Síguenos en nuestras <strong>redes sociales</strong>
              </h1>
              <h1 className="subtitle-style sm-subtitle sms-mobile">
                Redes <strong>sociales</strong>
              </h1>
              <div className="social-media-icons">
                {data.data.map((sm, i) => {
                  return (
                    <a
                      key={i}
                      className="anchor-sm"
                      href={sm.href}
                      title={sm.title}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="social-media"
                        onMouseOver={(e) => (e.target.src = sm.hover)}
                        onMouseLeave={(e) => (e.target.src = sm.src)}
                        src={sm.src}
                        alt={sm.alt}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="chat-btn-container">
          <a
            className="chat-btn"
            href="https://api.whatsapp.com/message/CNBXXM35FIWBN1?autoload=1&app_absent=0"
            target="_blank"
            title="Chat"
            rel="noreferrer"
          >
            Chatea con uno de <strong>nuestros asesores</strong>
          </a>
        </div>
        <div className="copy">
          &copy; Otacc {currentYear}. Todos los derechos reservados.{" "}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
