import React, { useContext } from "react";
import data from "../../json/benefit.json";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Carousel from "react-grid-carousel";

//Styles
import "./BenefitSection.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const BenefitSection = ({ wide }) => {
  const { benefits, benefitInView } = useContext(NavbarContext);

  const elements = [];

  data.data.map((item, i) => {
    return elements.push(
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "1rem",
        }}
        className="benefit"
      >
        <img
          src={item.src}
          alt={item.alt}
          className="benefit-icon"
          style={{ width: "5rem", height: "5rem" }}
        />
        <div
          style={{
            fontFamily: "ClanProBold",
            color: "#ffffff",
            fontSize: "0.9rem",
          }}
          className="text-benefit"
        >
          {item.subtitle}
        </div>
      </div>
    );
  });

  return (
    <section
      ref={benefits}
      className={`benefit-section ${benefitInView ? "show-benefit" : ""}`}
      id="benefits"
    >
      <img
        className="benefit-img-bg"
        src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/bgs/home-page/home-bg-1.webp"
        alt=""
        loading="lazy"
        rel="preconnect"
      />
      <div className="benefit-subcontainer">
        <div className="benefit-title-container">
          <h2 className="title-section">
            Proyecto VIS <strong>Aplica Subsidio</strong>
          </h2>
        </div>
        <div className="benefit-location-icon-container">
          <div className="bl-icon">
            <img
              className="bl-icon-img"
              src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/icons/location-icon.webp"
              rel="preconnect"
              alt=""
            />
            <h1>Zipaquirá</h1>
          </div>
          <div className="bl-icon bl-icon-copy">
            {/* <img
              className="bl-icon-img"
              src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/icons/location-icon.webp"
              rel="preconnect"
              alt=""
            /> */}
            <h1>¡Lanzamiento nueva torre!</h1>
          </div>
        </div>
        <div className="benefits-info-container">
          {data.data.map((benefit, i) => {
            return (
              <div key={i} className="benefit-container">
                <img
                  src={benefit.src}
                  alt={benefit.alt}
                  className="benefit-icon"
                />
                <h1 className="benefit-subtitle">{benefit.subtitle}</h1>
              </div>
            );
          })}
        </div>
        <div className="carousel-mobile">
          <CarouselDos
            children={elements}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={"bottom"}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={false}
            transitionSpeed={0.1}
          />
        </div>
        <div className="carousel-tablet">
          <Carousel
            cols={wide >= 768 && wide <= 1023 ? 3 : 3}
            rows={1}
            loop
            autoplay={2500}
            showDots={true}
            mobileBreakpoint={479}
            hideArrow={true}
          >
            {data.data.map((benefit, i) => {
              return (
                <Carousel.Item key={i}>
                  <div
                    className="benefit"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "10%",
                      rowGap: "0.5rem",
                    }}
                  >
                    <img
                      src={benefit.src}
                      alt={benefit.alt}
                      className="benefit-icon"
                      style={{
                        width: "5rem",
                        height: "5rem",
                      }}
                    />
                    <div
                      className="text-benefit"
                      style={{
                        fontFamily: "ClanProBold",
                        color: "#ffffff",
                        fontSize: "1rem",
                        textAlign: "center",
                        width: "5rem",
                      }}
                    >
                      {benefit.subtitle}
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        <div className="benefit-2024-txt-container">
          <div className="b2024t-subcontainer">
            <img
              className="b2024t-deco-img"
              src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/icons/benefit-gold-icon.svg"
              rel="preconnect"
              alt=""
            />
            <h1 className="b2024t-txt">
              ¡Lanzamiento de{" "}
              <strong>locales comerciales!</strong>
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitSection;
