import React, { useEffect, useState } from "react";

//Style
import "./Form.css";

const Form = ({ deploy, wide }) => {
  const [view, setView] = useState(false);

  const [checkedValue, setCheckedValue] = useState(false);

  const [disabledBtn, setDisabledBtn] = useState(true);

  const [selectedOpt, setSelectedOpt] = useState(
    <option value="" selected>
      --Ninguno--
    </option>
  );

  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    "00NRQ000000fTu5": "",
    "00NRQ000001z2Qv": "",
    "00NRQ000002QCyr": "",
    checked: false,
  });

  const handleViewForm = () => {
    setView(!view);
  };

  const params = new URLSearchParams(window.location.search);
  const source = params.get("utm_source");
  const campaign = params.get("utm_campaign");

  useEffect(() => {
    if (!source || source === "") {
      setSelectedOpt(
        <option value="Otra Fuente" selected>
          Otra Fuente
        </option>
      );
    } else {
      setSelectedOpt(
        <option value={source} selected>
          {source}
        </option>
      );
    }
  }, [source]);

  const handleForm = (e) => {
    e.target.name === "checked" && setCheckedValue(!checkedValue);
    const valueCheck = e.target.name === "checked" && e.target.checked;

    if (e.target.name === "first_name" || e.target.name === "last_name") {
      if (e.target.value.trim() === "") {
        e.target.nextElementSibling.classList.remove("hide");
      } else {
        e.target.nextElementSibling.classList.add("hide");
      }
    }

    if (e.target.name === "email") {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (emailPattern.test(e.target.value)) {
        e.target.nextElementSibling.classList.add("hide");
      } else {
        e.target.nextElementSibling.classList.remove("hide");
      }
    }

    if (e.target.name === "00NRQ000000fTu5") {
      e.target.value = e.target.value.replace(/\D/g, "");

      if (e.target.value.trim() !== "") {
        e.target.nextElementSibling.classList.add("hide");
      } else {
        e.target.nextElementSibling.classList.remove("hide");
      }
    }

    if (e.target.name === "00NRQ000001z2Qv") {
      if (e.target.value.trim() !== "") {
        e.target.nextElementSibling.classList.add("hide");
      } else {
        e.target.nextElementSibling.classList.remove("hide");
      }
    }

    if (e.target.name === "mobile") {
      e.target.value = e.target.value.replace(/\D/g, "");

      if (/^\s*3/.test(e.target.value.trim())) {
        e.target.nextElementSibling.classList.add("hide");
      } else {
        e.target.nextElementSibling.classList.remove("hide");
      }
    }

    if (e.target.name === "checked") {
      if (valueCheck === true) {
        e.target.parentElement.classList.remove("error");
      } else {
        e.target.parentElement.classList.add("error");
      }
    }

    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
      checked: valueCheck,
    });

    if (
      userInfo.first_name &&
      userInfo.first_name !== "" &&
      userInfo.last_name &&
      userInfo.last_name !== "" &&
      userInfo.email &&
      userInfo.email !== "" &&
      userInfo["00NRQ000000fTu5"] &&
      userInfo["00NRQ000000fTu5"] !== "" &&
      userInfo["00NRQ000001z2Qv"] &&
      userInfo["00NRQ000001z2Qv"] !== "" &&
      userInfo.mobile &&
      userInfo.mobile !== "" &&
      valueCheck !== false
    ) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  return (
    <div
      id="Pestaña_Formulario"
      className={
        wide > 1023 && deploy === true
          ? "form-container deployed"
          : !view
          ? "form-container closed"
          : "form-container open"
      }
    >
      <div
        className={
          wide > 1023 && deploy ? "label-form hiden-label" : "label-form"
        }
      >
        <p onClick={handleViewForm} className="label-head-text">
          Solicita más Información
        </p>
      </div>
      <div
        className={
          wide > 1023 && deploy === true
            ? "form-content form-deployed"
            : "form-content"
        }
      >
        <p className="form-title">Solicita más Información</p>
        <form
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          method="POST"
          onChange={handleForm}
          // onSubmit={handleSubmit}
          className="form-input-container"
        >
          <input type="hidden" name="oid" value="00D4W000008GmFk" />
          <input
            style={{ display: "none" }}
            id="00NRQ000000CElZ"
            name="00NRQ000000CElZ"
            type="checkbox"
            value="1"
            checked
          />
          <input
            id="00NRQ000002QCyr"
            maxlength="255"
            name="00NRQ000002QCyr"
            size="20"
            type="text"
            value={window.location.href}
            style={{ display: "none" }}
          />
          <input
            id="00NRQ000000Hrb7"
            maxlength="50"
            name="00NRQ000000Hrb7"
            size="20"
            type="text"
            value={"Formulario Landing"}
            style={{ display: "none" }}
          />
          <input
            type="hidden"
            name="retURL"
            value="https://miradordelasabana.otacc.com/agradecimientos"
          />
          <input
            id="00NRQ000000BQjx"
            maxLength="50"
            name="00NRQ000000BQjx"
            size="20"
            type="text"
            style={{ display: "none" }}
            className="hide-input"
            value={campaign ? campaign : "Otro"}
          />
          <select
            style={{ display: "none" }}
            id="00N4W00000ScMLv"
            name="00N4W00000ScMLv"
            title="Fuente"
            className="hide-select"
          >
            {selectedOpt}
          </select>
          <label htmlFor="first_name">
            <input
              id="first_name"
              maxLength="40"
              name="first_name"
              size="20"
              onChange={(e) => handleForm(e)}
              value={userInfo.first_name}
              type="text"
              placeholder="Nombre*"
              className="input"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label htmlFor="last_name">
            <input
              id="last_name"
              maxLength="80"
              name="last_name"
              size="20"
              onChange={(e) => handleForm(e)}
              value={userInfo.last_name}
              type="text"
              placeholder="Apellido*"
              className="input"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label htmlFor="email">
            <input
              id="email"
              maxLength="80"
              name="email"
              size="20"
              onChange={(e) => handleForm(e)}
              value={userInfo.email}
              type="email"
              placeholder="Correo electronico*"
              className="input"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label htmlFor="00NRQ000000fTu5">
            <input
              id="00NRQ000000fTu5"
              name="00NRQ000000fTu5"
              ize="20"
              type="text"
              onChange={(e) => handleForm(e)}
              value={userInfo["00NRQ000000fTu5"]}
              placeholder="No. de Documento*"
              className="input"
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label htmlFor="mobile">
            <input
              id="mobile"
              name="mobile"
              size="20"
              onChange={(e) => handleForm(e)}
              value={userInfo.mobile}
              type="text"
              placeholder="Celular*"
              className="input"
              maxLength={10}
            />
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label htmlFor="00NRQ000001z2Qv">
            <select name="00NRQ000001z2Qv" id="salary" className="input select">
              <option value="">--Ingresos Mensuales--</option>
              <option value="Menor a $2.000.000">Menor a $2.000.000</option>
              <option value="Entre $2.000.000 y $4.000.000">
                Entre $2.000.000 y $4.000.000
              </option>
              <option value="Entre $4.000.001 y $6.000.000">
                Entre $4.000.001 y $6.000.000
              </option>
              <option value="Entre $6.000.001 y $8.000.000">
                Entre $6.000.001 y $8.000.000
              </option>
              <option value="Entre $8.000.001 y $10.000.000">
                Entre $8.000.001 y $10.000.000
              </option>
              <option value="Entre $10.000.001 y $15.000.000">
                Entre $10.000.001 y $15.000.000
              </option>
              <option value="Mayor a $15.000.000">Mayor a $15.000.000</option>
            </select>
            <p className="mandatory-text hide">Campo obligatorio</p>
          </label>
          <label className="label-advise">
            <input
              onChange={(e) => handleForm(e)}
              checked={checkedValue}
              name="checked"
              type="checkbox"
              className="checkbox-input"
            />{" "}
            <a
              className="anchor-check"
              href="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/docs/FI-067+POLITICA+DE+TRATAMIENTO+DE+LA+INFORMACI%C3%93N+OTACC+S.A..pdf"
              rel="noreferrer"
              target="_blank"
            >
              Acepto la política de tratamientos de datos.<p>Ver más</p>
            </a>
          </label>
          <button disabled={disabledBtn} type="submit" className="submit-form">
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
