import React, { useContext } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

//Data
import data from "../../json/projectType.json";

//Style
import "./AptoSection.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const AptoSection = ({ wide }) => {
  const { types } = useContext(NavbarContext);

  return (
    <section ref={types} className="apto-section" id="apartments">
      <img
        className="as-bg-img"
        src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/bgs/home-page/white-wall-bg.webp"
        alt=""
        rel="preconnect"
      />
      <div className="apto-subcontainer">
        <div className="apto-title-container">
          <h1 className="apto-title">
            Tipologias <strong>del Proyecto</strong>
          </h1>
        </div>
        <div className="apto-info-container">
          <div className="apto-gallery-container">
            <Carousel
              images={data.data[0].imgApto}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
              }}
              shouldLazyLoad={true}
              hasTransition={true}
              hasLeftButton={"centerLeft"}
              hasRightButton={"centerRight"}
              hasIndexBoard={false}
              hasMediaButton={false}
              shouldMaximizeOnClick={true}
              hasSizeButton={false}
              hasLeftButtonAtMax="centerLeft"
              hasRightButtonAtMax="centerRight"
              hasSizeButtonAtMax="topRight"
              shouldSwipeOnMouse={true}
              objectFit={"contain"}
              hasDotButtons={wide <= 767 ? "bottom" : false}
              hasThumbnails={wide <= 767 ? false : true}
              hasThumbnailsAtMax={true}
              canAutoPlay={true}
              isAutoPlaying={true}
              autoPlayInterval={2500}
              hasMediaButtonAtMax="topLeft"
            />
          </div>
          <div className="apto-info-subcontainer">
            <div className="area-info-container">
              <p className="info-text">Area construida:</p>
              <p className="info-subtitle">
                {data.data[0].totalArea}mt<sup>2</sup>{" "}
                <span className="sm-subtitle">aprox.</span>
              </p>
              <p className="info-text bold">Area privada:</p>
              <p className="info-subtitle">
                {data.data[0].privArea}mt<sup>2</sup>{" "}
                <span className="sm-subtitle">aprox.</span>
              </p>
            </div>
            <div className="info-text-container">
              {data.data[0].infoTxt.map((info, i) => {
                return (
                  <p className="info-p" key={i}>
                    {info}
                  </p>
                );
              })}
            </div>
            <a
              className="btn-view-apto"
              title="Descargar brochure"
              href="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/docs/Brochure+Mirador+de+La+Sabana+Zipaquir%C3%A1-24.pptx.pdf"
              rel="noreferrer"
              target="_blank"
            >
              Descargar&nbsp;<strong>Brochure</strong>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AptoSection;
