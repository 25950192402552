import React, { useContext } from "react";

//Styles
import "./HomeSection.css";

//Data
import bannerData from "../../json/bannerImgs.json";
import bannerDataMobile from "../../json/banerImgsMobile.json";
import bannerDataTabletSmall from "../../json/banerImgsTabletSmall.json";

//Context
import { NavbarContext } from "../../context/NavbarContext";

//Component
import BannerHome from "../BannerHome/BannerHome";

const HomeSection = ({ wide }) => {
  const { home } = useContext(NavbarContext);

  return (
    <section ref={home} className="home-section">
      <div className="home-tag-container">
        <div className="ht-subcontainer">
          <a
            aria-label="Ubicación Sala de Ventas"
            href="https://www.google.com/maps/dir//5.02609,-73.993199/@5.025981,-73.9932201,19.58z?entry=ttu"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="tag-img"
              src="https://pagina-otacc.s3.amazonaws.com/pages/mirador-de-la-sabana/icons/tag.webp"
              alt=""
              rel="preconnect"
              loading="lazy"
            />
          </a>
        </div>
      </div>
      <div className="man-img-container">
        <div className="bg-home-container standard">
          <BannerHome
            imgs={
              wide < 768
                ? bannerDataMobile.data
                : wide < 1023 && wide >= 768
                ? bannerDataTabletSmall.data
                : bannerData.data
            }
            heightBanner={wide < 768 ? "272px" : "560px"}
          />
        </div>
      </div>
    </section>
  );
};

export default HomeSection;
